
import { Component, Prop, Vue } from "vue-property-decorator";
import DeletePopover from "@/components/DeletePopover.vue";
import System from "@/models/System";
import Catalog from "@/models/Catalog";
import _ from "underscore";

@Component({
  components: {
    DeletePopover,
  },
})
export default class CatalogCard extends Vue {
  @Prop() name!: string;
  @Prop() type!: string;
  @Prop() data!: Array<Catalog>;
  @Prop() systems!: Array<System>;
  @Prop({
    default: true,
  })
  hasSystems!: boolean;

  batch = false;
  catalogSelected: Array<number> = [];
  dialogVisible = false;
  dialogTitle = "";
  addAction = false;
  selectedSystems: Array<number> = [];
  systemsOnUpdate: Array<System> | null = null;
  systemsOnSelected: Catalog[][] = [];

  getTagType(string: string) {
    const TYPES = [
      "primary",
      "warning",
      "info",
      "success",
      "danger",
      "success",
      "primary",
    ];
    const LETTER = string[0].toLowerCase();
    const NUMBER = LETTER.charCodeAt(0) - 97;
    const NUMBER_BASE = NUMBER.toString(TYPES.length);
    const NUMBER_MOD_BASE = parseInt(NUMBER_BASE) % TYPES.length;
    return TYPES[NUMBER_MOD_BASE];
  }

  deleteCatalog(id: number) {
    this.$emit("delete", id, this.type);
  }

  editDrawer(row: object) {
    this.$emit("edit", row, this.type);
  }

  newDrawer(row: object) {
    this.$emit("new", row, this.type);
  }

  addSystems() {
    this.$emit(
      "add-systems",
      this.selectedSystems,
      this.catalogSelected,
      this.type
    );
    this.closeDialog();
  }

  removeSystems() {
    this.$emit(
      "remove-systems",
      this.selectedSystems,
      this.catalogSelected,
      this.type
    );
    this.closeDialog();
  }

  openDialog(add: boolean) {
    this.addAction = add;
    this.filterSystems(add);
    this.dialogVisible = true;
    this.dialogTitle = add ? "Agregar Sistemas" : "Eliminar Sistemas";
  }

  closeDialog() {
    this.dialogVisible = false;
    this.selectedSystems = [];
  }

  selectionChange(val: Array<object>) {
    this.catalogSelected = _.pluck(val, "id");
    const systems = _.pluck(val, "systems");
    if (systems[0] !== undefined)
      this.systemsOnSelected = _.pluck(val, "systems");
  }

  filterSystems(action: boolean) {
    let tempSystems = [...this.systems];
    if (this.systemsOnSelected.length > 0) {
      const tempSystemsDelete: Array<System> = [];
      const idsSystems: Set<number> = new Set();
      this.systemsOnSelected.forEach(systemsOnSelected => {
        systemsOnSelected.forEach(systems => {
          idsSystems.add(systems.systemId);
        });
      });
      idsSystems.forEach(systemId => {
        if (action) {
          tempSystems = tempSystems.filter(system => system.id !== systemId);
        } else {
          tempSystemsDelete.push(
            this.systems.filter(({ id }) => id === systemId)[0]
          );
        }
      });
      this.systemsOnUpdate = action ? tempSystems : tempSystemsDelete;
    }
  }

  filterCatalog(value: number, row: any) {
    return _.find(row.systems, (s: any) => {
      return s.systemId == value;
    });
  }

  get systemFilter() {
    return _.map(this.systems, (s: System) => {
      return {
        text: s.name,
        value: s.id,
      };
    });
  }
}
